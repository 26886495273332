import React, { ReactElement, useEffect, useContext, useCallback } from "react";
import {
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { Button, Form } from "reactstrap";
import TextInput from "../components/inputs/TextInput";
import CustomContainer from "../components/Container";

export default function Login(): ReactElement {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const [loginFailed, setLoginFailed] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const { logInUser, handleAuthChange, loadingUser } = useContext(UserContext);

  useEffect(() => {
    handleAuthChange({
      cb: () => {
        navigate("/admin");
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReCaptchaVerify = useCallback(
    async (email: string, password: string) => {
      setLoginFailed(false);
      if (!executeRecaptcha) {
        setLoginFailed(true);
        console.log("Execute recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha("pandaAdminLogin");
      if (token) {
        logInUser(email, password);
      } else {
        setLoginFailed(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executeRecaptcha]
  );

  return (
    <CustomContainer id="login">
      {!loadingUser && (
        <Form
          onSubmit={(e: any) => {
            e.preventDefault();
            handleReCaptchaVerify(email, password);
          }}
        >
          <TextInput
            type="email"
            value={email}
            label={"Email"}
            id="login-email"
            placeholder="Enter your email..."
            handleChange={(e: any) => setEmail(e.target.value)}
            required={true}
          />
          <TextInput
            type="password"
            value={password}
            label={"Password"}
            id="login-password"
            placeholder="Enter your password..."
            handleChange={(e: any) => setPassword(e.target.value)}
            required={true}
            minLength={6}
          />
          <div className="my-2 d-flex justify-content-between">
            <div className="text-danger">{loginFailed && "Login failed!"}</div>
            <Button>{loadingUser ? "Loading..." : "Login"}</Button>
          </div>
        </Form>
      )}
    </CustomContainer>
  );
}
