export interface ITrackList {
  id: string;
  location: string;
  eventName: string;
  eventDate: any;
  artists: IArtist[];
  totalArtistCount?: number;
  tracks: ITrack[];
  withWerknummer: boolean;
  isGemaFree: boolean;
}

export interface ITrack {
  tracklistId: string;
  name: string;
  werknummer: string;
  author1: string;
  author2: string;
}

export interface IArtist {
  id: string;
  name: string;
}

export interface IAutocompleteData {
  name: string;
  id: string;
}

export const defaultTrack: ITrack = {
  tracklistId: "",
  werknummer: "",
  name: "",
  author1: "",
  author2: "",
};

export const defaultArtist: IArtist = {
  id: "",
  name: "",
};
