import { ReactElement } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function CaptchaProvider({ children }: any): ReactElement {
  return process.env.REACT_APP_CAPTCHA_SITE_KEY ? (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
      language="english"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: "head", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
      container={{
        // optional to render inside custom element
        element: "recaptcha",
        parameters: {
          badge: "bottomright", // optional, default undefined
          theme: "light", // optional, default undefined
        },
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  ) : (
    <></>
  );
}
