import {
  spanStyleRed,
  thStyleBeige,
  thStyleGray,
  thStyleLightBeige,
  thBorderRight,
  thSubheaderLarge,
  thSubheaderSmall,
} from "../../styles/excelTableStyle";

interface FootnoteProps {
  text: string;
}

function Footnote({ text }: FootnoteProps) {
  return <span style={{ fontSize: "8px", fontWeight: "400" }}>{text})</span>;
}

function Asterisk() {
  return <span style={spanStyleRed}>*</span>;
}

export const ExcelTableHeader = () => {
  return (
    <>
      <tr>
        <th
          rowSpan={2}
          style={{
            ...thStyleGray,
            ...thBorderRight,
            width: "350px",
            textAlign: "left",
          }}
        >
          WERKNUMMER / WERKFASSUNGSNUMMER
        </th>
        <th
          rowSpan={2}
          style={{
            ...thStyleBeige,
            ...thBorderRight,
            width: "550px",
            textAlign: "left",
          }}
        >
          TITEL <Asterisk />
          <Footnote text="2" />
        </th>
        <th
          rowSpan={2}
          style={{
            ...thStyleGray,
            ...thBorderRight,
            width: "300px",
            textAlign: "left",
          }}
        >
          SATZANGABE / SONSTIGE(R) Titel <Footnote text="3" />
        </th>
        <th
          rowSpan={2}
          style={{ ...thStyleLightBeige, ...thBorderRight, width: "150px" }}
        >
          ANZAHL MUSIKER / SAENGER <Asterisk />
          <Footnote text="4" />
        </th>
        <th
          rowSpan={2}
          style={{ ...thStyleLightBeige, ...thBorderRight, width: "150px" }}
        >
          SPIELDAUER <Asterisk />
          <Footnote text="5" /> (MM:SS)
        </th>
        <th
          rowSpan={1}
          colSpan={2}
          style={{ ...thStyleBeige, ...thBorderRight }}
        >
          INTERPRET / KOMPONIST <Asterisk />
          <Footnote text="6" />
        </th>
        <th
          rowSpan={1}
          colSpan={2}
          style={{ ...thStyleGray, ...thBorderRight }}
        >
          TEXTDICHTER
        </th>
        <th
          rowSpan={2}
          style={{ ...thStyleGray, ...thBorderRight, width: "150px" }}
        >
          VERLAG
        </th>
        <th
          rowSpan={2}
          style={{ ...thStyleLightBeige, ...thBorderRight, width: "150px" }}
        >
          Art der Musikwiedergabe (LIVE/TONTRÄGER) <Asterisk />
          <Footnote text="7" />
        </th>
        <th
          rowSpan={2}
          style={{ ...thStyleLightBeige, ...thBorderRight, width: "150px" }}
        >
          VERÖFFENTLICHES WERK <Asterisk />
          <Footnote text="8" />
        </th>
        <th
          rowSpan={2}
          style={{ ...thStyleGray, ...thBorderRight, width: "150px" }}
        >
          GROßES RECHT
        </th>
        <th
          rowSpan={2}
          style={{ ...thStyleGray, ...thBorderRight, width: "150px" }}
        >
          POTPOURRI (P) / FRAGMENT (F) <Footnote text="9" />
        </th>
        <th
          rowSpan={2}
          style={{ ...thStyleGray, ...thBorderRight, width: "150px" }}
        >
          ISWC
        </th>
        <th
          rowSpan={1}
          colSpan={2}
          style={{ ...thStyleGray, ...thBorderRight }}
        >
          BEARBEITER
        </th>
        <th
          rowSpan={1}
          colSpan={2}
          style={{ ...thStyleGray, ...thBorderRight }}
        >
          2. KOMPONIST
        </th>
        <th
          rowSpan={1}
          colSpan={2}
          style={{ ...thStyleGray, ...thBorderRight }}
        >
          2. TEXTDICHTER
        </th>
        <th
          rowSpan={1}
          colSpan={2}
          style={{ ...thStyleGray, ...thBorderRight }}
        >
          3. TEXTDICHTER
        </th>
        <th
          rowSpan={1}
          colSpan={2}
          style={{ ...thStyleGray, ...thBorderRight }}
        >
          SONSTIGE PERSON
        </th>
      </tr>
      <tr>
        <th rowSpan={1} style={{ ...thStyleBeige, ...thSubheaderLarge }}>
          Nachname <Asterisk />
        </th>
        <th
          rowSpan={1}
          style={{
            ...thStyleLightBeige,
            ...thBorderRight,
            ...thSubheaderSmall,
          }}
        >
          Vorname
        </th>

        <th rowSpan={1} style={{ ...thStyleGray, ...thSubheaderLarge }}>
          Nachname
        </th>
        <th
          rowSpan={1}
          style={{ ...thStyleGray, ...thBorderRight, ...thSubheaderSmall }}
        >
          Vorname
        </th>

        <th rowSpan={1} style={{ ...thStyleGray, ...thSubheaderLarge }}>
          Nachname
        </th>
        <th
          rowSpan={1}
          style={{ ...thStyleGray, ...thBorderRight, ...thSubheaderSmall }}
        >
          Vorname
        </th>

        <th rowSpan={1} style={{ ...thStyleGray, ...thSubheaderLarge }}>
          Nachname
        </th>
        <th
          rowSpan={1}
          style={{ ...thStyleGray, ...thBorderRight, ...thSubheaderSmall }}
        >
          Vorname
        </th>

        <th rowSpan={1} style={{ ...thStyleGray, ...thSubheaderLarge }}>
          Nachname
        </th>
        <th
          rowSpan={1}
          style={{ ...thStyleGray, ...thBorderRight, ...thSubheaderSmall }}
        >
          Vorname
        </th>

        <th rowSpan={1} style={{ ...thStyleGray, ...thSubheaderLarge }}>
          Nachname
        </th>
        <th
          rowSpan={1}
          style={{ ...thStyleGray, ...thBorderRight, ...thSubheaderSmall }}
        >
          Vorname
        </th>

        <th rowSpan={1} style={{ ...thStyleGray, ...thSubheaderLarge }}>
          Nachname
        </th>
        <th
          rowSpan={1}
          style={{ ...thStyleGray, ...thBorderRight, ...thSubheaderSmall }}
        >
          Vorname
        </th>
      </tr>
    </>
  );
};
