import { ReactElement, useContext } from "react";
import { Button, Container, Row } from "reactstrap";
import { ITrackList } from "../interfaces";
import { UserContext } from "../contexts/UserContext";
import DownloadComponent from "./DownloadComponent";
import { ListUl } from "react-bootstrap-icons";

interface IFormButtonsProps {
  trackListValues: ITrackList;
  readonly: boolean;
}

export default function TrackListFormButtons({
  trackListValues,
  readonly,
}: IFormButtonsProps): ReactElement {
  const { currentUser } = useContext(UserContext);

  const AllSetlistsButton = () => {
    return currentUser && currentUser.displayName ? (
      <Button
        onClick={(e) => {
          e.preventDefault();
          window.location.href = "/admin";
        }}
        size="lg"
      >
        <ListUl size={22} /> All playlists
      </Button>
    ) : (
      <></>
    );
  };

  const DownloadButton = () => {
    if (currentUser && trackListValues.id) {
      return <DownloadComponent trackListValues={trackListValues} />;
    }
    return null;
  };

  const SubmitButton = () => {
    if (currentUser && !readonly) {
      return (
        <Button color="danger" size="lg" type="submit">
          Submit form
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <Container className={`view-large mx-auto my-4 p-0`}>
        <div className="d-flex justify-content-between">
          <div className="text-left">
            <AllSetlistsButton />
          </div>
          <div className="text-right">
            <DownloadButton />
            <SubmitButton />
          </div>
        </div>
      </Container>
      <Container
        className="view-small container"
        style={{ marginTop: "1.5rem", marginBottom: "5rem" }}
      >
        <Row>
          <SubmitButton />
          <DownloadButton />
          <AllSetlistsButton />
        </Row>
      </Container>
    </>
  );
}
