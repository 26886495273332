import { ReactElement } from "react";
import { ITrack, IArtist } from "../../interfaces";
import { Row, Col } from "reactstrap";
import TextInput from "../inputs/TextInput";
import { AutoCompleteInput } from "../inputs/AutoCompleteInput/AutoCompleteInput";

interface IProps {
  item: ITrack;
  index: number;
  availableArtists: IArtist[];
  handleChange: (
    name: string,
    value: string,
    index: number,
    type: "tracks" | "artists"
  ) => void;
  withWerknummer?: boolean;
  readonly: boolean;
}

export default function TrackItem({
  item,
  index,
  availableArtists,
  withWerknummer,
  handleChange,
  readonly
}: IProps): ReactElement {
  return (
    <>
      <Row
        className="my-3"
        style={{
          padding: "20px 10px 10px",
          backgroundColor: "#e9ecef",
        }}
      >
        <Col xs="12" md={`${withWerknummer ? 9 : 12}`}>
          <TextInput
            id={`name-${index}`}
            type="text"
            value={item.name}
            label={`${index + 1}. Track title`}
            handleChange={(e: any) =>
              handleChange(e.target.name, e.target.value, index, "tracks")
            }
            markAsRequired={true}
            readonly={readonly}
          />
        </Col>
        {withWerknummer && (
          <Col xs="12" md="3">
            <TextInput
              id={`werknummer-${index}`}
              type="text"
              value={item.werknummer}
              label={`Werknummer`}
              handleChange={(e: any) =>
                handleChange(e.target.name, e.target.value, index, "tracks")
              }
              readonly={readonly}
            />
          </Col>
        )}
        <Col xs="12" sm="6">
          <AutoCompleteInput
            id={`author1-${index}`}
            data={availableArtists}
            value={item.author1}
            label="Composer / Author 1"
            handleChange={(name: string, value: string) =>
              handleChange(name, value, index, "tracks")
            }
            readonly={readonly}
          />
        </Col>
        <Col xs="12" sm="6">
          <AutoCompleteInput
            id={`author2-${index}`}
            data={availableArtists}
            value={item.author2}
            label="Lyrics / Author 2"
            handleChange={(name: string, value: string) =>
              handleChange(name, value, index, "tracks")
            }
            readonly={readonly}
          />
        </Col>
      </Row>
    </>
  );
}
