// Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAWLnfhyAr2eLLkKSreN6U9CyoJbudVcE8",
  authDomain: "panda-gema.firebaseapp.com",
  projectId: "panda-gema",
  storageBucket: "panda-gema.appspot.com",
  messagingSenderId: "993727496888",
  appId: "1:993727496888:web:a9b5767a38eda318b26a4d",
  measurementId: "G-VRESKFE9PT",
};

// //TEST DB
// const firebaseConfig = {
//   apiKey: "AIzaSyDXDH9OjIuBt2oE9gL2PkJeG1tQWNBL02M",
//   authDomain: "gema-panda.firebaseapp.com",
//   databaseURL:
//     "https://gema-panda-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "gema-panda",
//   storageBucket: "gema-panda.appspot.com",
//   messagingSenderId: "352703727656",
//   appId: "1:352703727656:web:07efcbb8c94507e1278d1d",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
