import React, { ReactElement } from "react";
import Asterisk from "../Asterisk";

interface IProps {
  id: string;
  value: string;
  handleChange: any;
  handleFocusOut?: any;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  markAsRequired?: boolean;
  type: "text" | "number" | "email" | "password";
  label?: string;
  minLength?: number;
  maxLength?: number;
  autoComplete?: "off" | "on";
  innerRef?: any;
}

export default function TextInput({
  id,
  value,
  handleChange,
  handleFocusOut,
  placeholder,
  readonly,
  type,
  label,
  minLength,
  maxLength,
  autoComplete,
  required,
  markAsRequired,
  innerRef,
}: IProps): ReactElement {
  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    handleFocusOut && handleFocusOut(e);
  };

  return (
    <div className="form-floating text-bold mb-3">
      <input
        type={type}
        className="form-control"
        id={id}
        name={id}
        placeholder={placeholder ? placeholder : label}
        value={value}
        onChange={handleChange}
        readOnly={readonly}
        disabled={readonly}
        minLength={minLength}
        maxLength={maxLength}
        autoComplete={autoComplete}
        required={required}
        ref={innerRef}
        onBlur={(e) => handleOnBlur(e)}
      />
      <label htmlFor={id}>
        {label} {(required || markAsRequired) && <Asterisk />}
      </label>
    </div>
  );
}
