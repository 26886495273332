import React, { ReactElement, useState } from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  signInAnonymously,
  //updateProfile,
  signOut
} from "@firebase/auth";
import { auth } from "./../firebase";

interface Props {
  children:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactNodeArray
    | React.ReactPortal;
}

export interface userTypes {
  isReadonlyUser: boolean;
  displayName: string | null;
  userId: string;
  avatar?: string | null;
}

interface contextTypes {
  loadingUser: boolean;
  currentUser: userTypes | null;
  logInUser(email: string, password: string): Promise<void>;
  signInAnomyously(): Promise<void>;
  signOutUser(): Promise<void>;
  deleteUser(uid: string | undefined): Promise<void>;
  handleAuthChange: (params: { cb?: VoidFunction; err?: VoidFunction }) => void;
}

const contextDefaultVal: contextTypes = {
  loadingUser: false,
  currentUser: null,
  logInUser: async () => {},
  signInAnomyously: async () => {},
  signOutUser: async () => {},
  deleteUser: async () => {},
  handleAuthChange: () => {},
};

export const UserContext = React.createContext<contextTypes>(contextDefaultVal);

export default function UserContextProvider({ children }: Props): ReactElement {
  const [currentUser, setCurrentUser] = useState<userTypes | null>(null);
  const [loadingUser, setLoadingUser] = useState(false);

  const logInUser = async (email: string, password: string) => {
    try {
      setLoadingUser(true);

      //local: localStorage (persists only after log out)
      //session: sessionStorage (resets in new browser window)
      //none: resets on refresh
      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return signInWithEmailAndPassword(auth, email, password);
        })
        .catch((error) => {
          // Handle Errors here.
          console.log(error.code);
          console.log(error.message);
        });
    } catch (error) {
      alert(error);
    } finally {
      setLoadingUser(false);
    }
  };

  const signInAnomyously = async () => {
    
    const authorizedUser = localStorage.getItem("currentUser");
    if (
      !auth.currentUser &&
      (!authorizedUser ||
        (authorizedUser && !JSON.parse(authorizedUser).displayName))
    ) {
      await signInAnonymously(auth).then(() => {
        localStorage.setItem("currentUser", JSON.stringify(auth.currentUser));
      });
    }
  }

  const signOutUser = async () => {
    try {
      await signOut(auth);
      setLoadingUser(false);
    } catch (error) {
      alert(error);
    }
  };

  const deleteUser = async(uid: string | undefined) => {
    if(uid) {
      auth.currentUser?.delete()
    }
  }

  const handleAuthChange = async (params: {
    cb?: VoidFunction;
    err?: VoidFunction;
  }) => {
    setLoadingUser(true);
    onAuthStateChanged(auth, (user) => {
      if (user === null) {
        setCurrentUser(null);
        localStorage.removeItem("currentUser");
        params.err && params.err();
        setLoadingUser(false);
      } else {
        const authorizedUser = {
          isReadonlyUser: (user.displayName && user.displayName.indexOf("user") > 1) ? true : false,
          displayName: user.displayName,
          userId: user.uid,
          avatar: user.photoURL,
        };
        //update newly created user - import updateProfile from firebase
        // updateProfile(user, {
        //   displayName: "Dana TestUser"
        // });

        setCurrentUser(authorizedUser);
        if (authorizedUser.displayName) {
          localStorage.setItem("currentUser", JSON.stringify(authorizedUser));
         params.cb && params.cb();
        }

       setLoadingUser(false);
      }
    });
  };

  return (
    <UserContext.Provider
      value={{
        loadingUser,
        currentUser,
        logInUser,
        signInAnomyously,
        handleAuthChange,
        signOutUser,
        deleteUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
