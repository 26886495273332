// Object.defineProperty(exports, "__esModule", { value: true });
// exports.useDownloadExcel = void 0;
// const react_1 = require("react");
// const lib_1 = require("../lib");

// function useDownloadExcel({ currentTableRef, filename, sheet }) {
//   const payload = {
//     currentTableRef,
//     filename,
//     sheet,
//   };

//   const excelData = (0, react_1.useMemo)(() => {
//     if (currentTableRef) {
//       return (0, lib_1.excel)(payload);
//     }
//     return null;
//   }, [currentTableRef, filename, sheet]);

//   return excelData;
// }

// exports.useDownloadExcel = useDownloadExcel;

import { useMemo } from 'react';
import { excel } from '../lib';

export function useDownloadExcel({ currentTableRef, filename, sheet }) {
  const payload = {
    currentTableRef,
    filename,
    sheet,
  };

  const excelData = useMemo(() => {
    if (currentTableRef) {
      return excel(payload);
    }
    return null;
  }, [currentTableRef, filename, sheet]);

  return excelData;
}
