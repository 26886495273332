import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "@firebase/auth";

interface Props {
  children: React.ReactNode;
}

const AuthChecker = ({ children }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const authorizedUser = localStorage.getItem("currentUser");
    if (
      !auth.currentUser &&
      (!authorizedUser ||
        (authorizedUser && !JSON.parse(authorizedUser).displayName))
    ) {
      navigate("/login");
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default AuthChecker;
