import { MutableRefObject, useEffect, useRef, useState } from "react";
import { ITrackList } from "../../interfaces";
import moment from "moment";
import { useDownloadExcel } from "../../react-export-table-to-excel";

import { thStyle, thTextLeft } from "../../styles/excelTableStyle";
import { ExcelTableInfoHeader } from "./ExcelTableInfoHeader";
import { ExcelTableHeader } from "./ExcelTableHeader";

interface IExcelTableProps {
  tableItems?: ITrackList;
  downloadTimeStamp: number;
}

const fileNameConstant = "PANDAmusikfolge";

const getFileName = (eventDate: any, eventName?: string) => {
  const formattedDate = moment(eventDate).format("YYMMDD");
  return `${formattedDate}_${fileNameConstant}_${eventName}`;
};

export const ExcelTable = ({
  tableItems,
  downloadTimeStamp,
}: IExcelTableProps) => {
  const [downloadTime, setDownloadTime] = useState<number>();

  const ref = useRef() as MutableRefObject<HTMLTableElement>;

  useEffect(() => {
    tableItems &&
      tableItems.id != null &&
      ref &&
      ref.current &&
      setDownloadTime(downloadTimeStamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadTimeStamp, tableItems]);

  const download = useDownloadExcel({
    currentTableRef: ref.current,
    filename: getFileName(tableItems?.eventDate, tableItems?.eventName),
    sheet: "Setlist Template",
  });

  useEffect(() => {
    if (downloadTime && ref.current) {
      download.onDownload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadTime, ref, ref.current]);

  return (
    <div className="d-none" id="download-list-table">
      {tableItems && (
        <table ref={ref}>
          <thead>
            <ExcelTableInfoHeader />
            <ExcelTableHeader />
          </thead>

          <tbody>
            {tableItems.tracks.map((track, index) => {
              return (
                <tr key={index}>
                  <td style={{ ...thStyle, ...thTextLeft }}>
                    {track.werknummer ? track.werknummer : ""}
                  </td>
                  <td style={thStyle}>{track.name}</td>
                  <td style={thStyle}></td>
                  <td style={thStyle}>
                    {tableItems.totalArtistCount ||
                      (tableItems?.artists ? tableItems.artists.length : "")}
                  </td>
                  <td style={thStyle}></td>
                  <td style={thStyle}>{track.author1 ? track.author1 : ""}</td>
                  <td style={thStyle}></td>
                  <td style={thStyle}>{track.author2 ? track.author2 : ""}</td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}>Live</td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                  <td style={thStyle}></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
