import {
  spanStyleRedBold,
  thStyle,
  thStyleRed,
  thTextLeft,
} from "../../styles/excelTableStyle";

export const ExcelTableInfoHeader = () => {
  return (
    <>
      <tr>
        <th colSpan={2} rowSpan={2} style={thStyleRed}>
          Excel-Vorlage zum Hochladen von Titeln
        </th>
        <th style={thTextLeft} rowSpan={1}>
          Version: 1.2
        </th>
      </tr>
      <tr></tr>
      <tr>
        <th colSpan={2} rowSpan={2} style={{ ...thStyle, ...thTextLeft }}>
          <span style={spanStyleRedBold}>1) PFLICHTFELDER (*): </span>Die
          Spalten B und F sind immer Pflichtangaben. Die Spalten B, E, F, K und
          ggf. L sind Pflichtangaben beim Sprechtheater und bei Wortkabarett.
          Die Spalten B, D, F und K sind Pflichtangaben bei Konzerten der
          ernsten Musik.
        </th>
      </tr>
      <tr></tr>
      <tr>
        <th colSpan={2} rowSpan={2} style={{ ...thStyle, ...thTextLeft }}>
          <span style={spanStyleRedBold}>2) TITEL: </span>Tragen Sie bitte
          Titel, die mehrfach gespielt wurden (zum Beispiel Zugaben), in mehrere
          Zeilen ein.
        </th>
        <th> </th>
      </tr>
      <tr></tr>
      <tr>
        <th colSpan={2} rowSpan={2} style={{ ...thStyle, ...thTextLeft }}>
          <span style={spanStyleRedBold}>
            3) SATZANGABEN / SONSTIGE(R) TITEL:{" "}
          </span>
          Einzelne Sätze brauchen nur angegeben werden, wenn nicht das ganze
          Werk gespielt wird.
        </th>
      </tr>
      <tr></tr>
      <tr>
        <th colSpan={2} style={{ ...thStyle, ...thTextLeft }}>
          <span style={spanStyleRedBold}>4) ANZAHL MUSIKER / SÄNGER: </span>Die
          Anzahl der Musiker und Sänger ist pro Werk bei Konzerten der Ernsten
          Musik eine Pflichtangabe.
        </th>
      </tr>

      <tr>
        <th colSpan={2} style={{ ...thStyle, ...thTextLeft }}>
          <span style={spanStyleRedBold}>5) SPIELDAUER: </span>Die Spieldauer
          ist bei Bühnen-/Theateraufführungen und bei Wortkabarett eine
          Pflichtangabe, auch bei klassischen Konzerten bitten wir um Angabe der
          tatsächlichen Spieldauer.
        </th>
      </tr>
      <tr>
        <th colSpan={2} rowSpan={2} style={{ ...thStyle, ...thTextLeft }}>
          <span style={spanStyleRedBold}>6) INTERPRET / KOMPONIST: </span>
          Bitte tragen Sie in die Spalte Interpret / Komponist (Spalte F-G)
          immer nur jeweils eine Person in das Feld ein. Bei mehreren
          Komponisten etc. nutzen Sie bitte die weiteren Spalten R-Y.
        </th>
      </tr>
      <tr></tr>
      <tr>
        <th colSpan={2} rowSpan={2} style={{ ...thStyle, ...thTextLeft }}>
          <span style={spanStyleRedBold}>
            {" "}
            7) ART DER MUSIKWIEDERGABE (LIVE/TONTRÄGER):{" "}
          </span>
          Bei Bühnen- und Theateraufführungen sowie Wortkabarett benötigen wir
          die Angabe, ob die Werke Live oder vom Tonträger wiedergegeben werden.
        </th>
      </tr>
      <tr></tr>
      <tr>
        <th colSpan={2} rowSpan={2} style={{ ...thStyle, ...thTextLeft }}>
          <span style={spanStyleRedBold}>8) VERÖFFENTLICHTES WERK: </span>
          Erfolgt die Wiedergabe per Tonträger wählen Sie bitte aus, ob der
          Tonträger veröffentlicht ist. Verlegt ist der Tonträger in der Regel,
          wenn er im Handel erschienen ist.
        </th>
      </tr>
      <tr></tr>
      <tr>
        <th colSpan={2} style={{ ...thStyle, ...thTextLeft }}>
          <span style={spanStyleRedBold}>9) POTPOURRI / FRAGMENT: </span>
          Wenn Sie nur einen sehr kurzen Ausschnitt eines Werkes gespielt haben,
          wählen Sie bitte F (= Fragment) aus. Wenn Sie mehrere kurze
          Ausschnitte verschiedener Werke gespielt haben, wählen Sie bitte P (=
          Potpourri) aus.
        </th>
      </tr>
      <tr>
        <th colSpan={2} style={{ ...thStyleRed, ...thTextLeft }}>
          Setlist
        </th>
      </tr>
    </>
  );
};
