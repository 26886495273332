import { ReactElement, useContext } from "react";
import { useLocation } from "react-router";
import { Navbar, Button, Row, Col, Container } from "reactstrap";
import withRouter from "../withRouter";
import { UserContext } from "../contexts/UserContext";
import { TracklistContext } from "../contexts/TracklistContext";
import Logo from "./Logo";
import { PersonCircle } from "react-bootstrap-icons";

const NavBar = (): ReactElement => {
  const location = useLocation();
  const { currentUser, signOutUser, loadingUser } = useContext(UserContext);
  const { resetTrackList } = useContext(TracklistContext);

  const handleSignOut = () => {
    signOutUser();
    resetTrackList();
  };

  return (
    <Navbar className="justify-content-between border border-b-1 py-3">
      <Container>
        {!loadingUser && (
          <Row style={{ width: "100%", margin: "0" }}>
            <Col xs={12} sm={6} id="logo" className="d-flex align-items-center">
              {/* <a href={`${currentUser ? "/admin" : "/"}`}>
                <Logo />
              </a> */}
              <span>
                <h3 className="mx-4 my-0">GEMA Setlist</h3>
              </span>
            </Col>

            <Col
              xs={12}
              sm={6}
              id={"user"}
              className="d-flex align-items-center"
            >
              {currentUser && currentUser.displayName ? (
                <>
                  <span className="mx-4">
                    <PersonCircle size="22" /> Logged in as{" "}
                    <strong>{currentUser.displayName}</strong>
                  </span>
                  <span>
                    <Button onClick={() => handleSignOut()}>Log Out</Button>
                  </span>
                </>
              ) : (
                !(location?.pathname === "/login") && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = "/login";
                    }}
                    color="danger"
                  >
                    Log In
                  </Button>
                )
              )}
            </Col>
          </Row>
        )}
      </Container>
    </Navbar>
  );
};

export default withRouter(NavBar);
