import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "./styles/style.scss";
import "./styles/checkbox.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UserContextProvider from "./contexts/UserContext";
import TodosContextProvider from "./contexts/TodosContext";
import ArtistContextProvider from "./contexts/ArtistContext";
import TracklistContextProvider from "./contexts/TracklistContext";
import CaptchaProvider from "./recaptcha/GoogleReCaptchaProvider";

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <TodosContextProvider>
        <ArtistContextProvider>
          <TracklistContextProvider>
            <CaptchaProvider>
              <App />
            </CaptchaProvider>
          </TracklistContextProvider>
        </ArtistContextProvider>
      </TodosContextProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
