import React, { ReactElement } from "react";
import {
  query,
  collection,
  getDoc,
  getDocs,
  doc,
  setDoc
} from "@firebase/firestore";
import { db } from "../firebase";
import { v4 as uuid } from "uuid";
import { IArtist } from "../interfaces";

interface Props {
  children:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactNodeArray
    | React.ReactPortal;
}

interface contextTypes {
  loading: boolean;
  artists: IArtist[];
  addArtist(name: string): Promise<void>;
  getArtists(): Promise<void>;
}

const contextDefaultVal: contextTypes = {
  loading: false,
  artists: [],
  addArtist: async () => {},
  getArtists: async () => {},
};

export const ArtistContext =
  React.createContext<contextTypes>(contextDefaultVal);

export default function ArtistContextProvider({
  children,
}: Props): ReactElement {
  const [artists, setArtists] = React.useState<IArtist[]>([]);
  const [loading] = React.useState(false);

  const addArtist = async (name: string) => {
    try {
      // document reference to be added
      const docRef = doc(db, "artists", name);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log(name, " already exists!");
        console.log("Document data:", docSnap.data());
      } else {
        await setDoc(docRef, { id: uuid(), name: name });
        alert(`Item ${name} added!`);
      }
    } catch (error) {
      alert(error);
    }
  };

  const getArtists = async () => {
    try {
      // query to get only the documents that matches logged in user id
      // const q = query(collection(db, "todo"), where("userId", "==", userId));
      // const querySnapshot = await getDocs(q);

      const q = query(collection(db, "artists"));
      const querySnapshot = await getDocs(q);

      // // reset the todo items value
      setArtists([]);
      //const artists = querySnapshot.docs.map((doc) => doc.data());

      // debugger;
      // // map through the query result and assign the value to the todoItems state
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        setArtists((prev) => [
          ...prev,
          {
            id: doc.id,
            name: data.name,
          },
        ]);
      });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <ArtistContext.Provider
      value={{
        loading,
        artists,
        addArtist,
        getArtists,
      }}
    >
      {children}
    </ArtistContext.Provider>
  );
}
