import {
  ChangeEvent,
  FC,
  useState,
  useEffect,
  RefObject,
  createRef,
} from "react";
import styled from "styled-components";
import TextInput from "../TextInput";
import { IAutocompleteData } from "../../../interfaces";

import {
  AutoCompleteContainer,
  AutoCompleteItem,
  AutoCompleteItemButton,
} from "./styles";

const Root = styled.div`
  position: relative;
`;

interface IAutoCompleteComponentProps {
  id: string;
  label: string;
  value: string;
  data: any[];
  required?: boolean;
  readonly: boolean;
  handleChange: (name: string, value: string) => void;
  handleFocusOut?: (name: string, value: string) => void;
}
export const AutoCompleteInput: FC<IAutoCompleteComponentProps> = ({
  id,
  label,
  value,
  data,
  required,
  handleChange,
  handleFocusOut,
  readonly
}) => {
  const [search, setSearch] = useState<any>({
    text: "",
    suggestions: [],
  });
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const searchBox = createRef<HTMLInputElement>();

  const useKeyPress = function (
    targetKey: string,
    ref: RefObject<HTMLInputElement>
  ) {
    const [keyPressed, setKeyPressed] = useState(false);

    function downHandler({ key }: { key: string }) {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }

    const upHandler = ({ key }: { key: string }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    useEffect(() => {
      ref.current?.addEventListener("keydown", downHandler);
      ref.current?.addEventListener("keyup", upHandler);

      return () => {
        ref.current?.removeEventListener("keydown", downHandler);
        ref.current?.removeEventListener("keyup", upHandler);
      };
    });

    return keyPressed;
  };

  const downPress = useKeyPress("ArrowDown", searchBox);
  const upPress = useKeyPress("ArrowUp", searchBox);
  const enterPress = useKeyPress("Enter", searchBox);
  const [cursor, setCursor] = useState<number>(0);
  const [hovered, setHovered] = useState<any>(undefined);

  const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      //Starts with:
      // const regex = new RegExp(`^${value.toLowerCase()}`); //starts with
      // suggestions = data
      //   .sort()
      //   .filter((v: IAutocompleteData) => regex.test(v.name.toLowerCase()));

      suggestions = data
        .sort()
        .filter((v: IAutocompleteData) => v.name.toLowerCase().indexOf(value.toLowerCase()) > -1);

      if (suggestions.length > 0) {
        setIsComponentVisible(true);
      } else setIsComponentVisible(false);
    } else {
      setIsComponentVisible(false);
    }
    setSearch({ suggestions, text: value });
    handleChange(id, value);
  };

  const suggestionSelected = (value: IAutocompleteData) => {
    setIsComponentVisible(false);

    setSearch({
      text: value.name,
      suggestions: [],
    });

    handleChange(id, value.name);
  };

  const onFocusOut = (id: string, value: string) => {
    if (handleFocusOut && !isComponentVisible) handleFocusOut(id, value);
  };

  useEffect(() => {
    if (suggestions.length && downPress) {
      setCursor((prevState) =>
        prevState < suggestions.length - 1 ? prevState + 1 : prevState
      );
    }
  }, [downPress]);
  useEffect(() => {
    if (suggestions.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);
  useEffect(() => {
    if (suggestions.length && enterPress) {
      suggestionSelected(suggestions[cursor]);
    }
  }, [cursor, enterPress]);
  useEffect(() => {
    if (suggestions.length && hovered) {
      setCursor(suggestions.indexOf(hovered));
    }
  }, [hovered]);

  const { suggestions } = search;

  return (
    <Root>
      <div
        onClick={() => setIsComponentVisible(false)}
        style={{
          display: isComponentVisible ? "block" : "none",
        }}
      />
      <TextInput
        id={id}
        value={search.text ? search.text : value}
        type="text"
        handleChange={onTextChanged}
        handleFocusOut={() => onFocusOut(id, value)}
        label={label}
        autoComplete={"off"}
        innerRef={searchBox}
        required={required}
        readonly={readonly}
      />
      {suggestions.length > 0 && isComponentVisible && (
        <AutoCompleteContainer>
          {suggestions.map((item: IAutocompleteData, i: number) => (
            <AutoCompleteItem
              key={item.id}
              className={cursor === i ? "active" : ""}
              onMouseEnter={() => setHovered(item)}
              onMouseLeave={() => setHovered(undefined)}
            >
              <AutoCompleteItemButton
                key={item.id}
                onClick={() => suggestionSelected(item)}
              >
                {item.name}
              </AutoCompleteItemButton>
            </AutoCompleteItem>
          ))}
        </AutoCompleteContainer>
      )}
    </Root>
  );
};
