import { ReactElement, useContext } from "react";
import { Row, Col, Container } from "reactstrap";
import withRouter from "../withRouter";
import { UserContext } from "../contexts/UserContext";
import { TracklistContext } from "../contexts/TracklistContext";
var pjson = require('./../../package.json');

const Footer = (): ReactElement => {
  
  const { loadingUser } = useContext(UserContext);
  const { loading } = useContext(TracklistContext);
  
  return (
    <div id="footer">
      {!loading && !loadingUser &&
        <Container>
          <Row style={{ width: "100%", margin: "0" }}>
            <Col xs={12} className="text-center">
              v.{pjson.version} | &copy; Bogdana Bondarenko <a href="https://danabond.de" target="_blank">danabond.de</a>
            </Col>
          </Row>
        </Container>
      }
    </div>
  );
}

export default withRouter(Footer);


