import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { TracklistContext } from "./contexts/TracklistContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import routes from "./routes";
import AuthChecker from "./components/AuthChecker";
import Layout from "./components/Layout";
import Spinner from "./components/Spinner";
import { UserContext } from "./contexts/UserContext";

function App() {
  const { loading } = useContext(TracklistContext);
  const { loadingUser } = useContext(UserContext);
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Layout>
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                route.protected ? (
                  <AuthChecker>
                    <route.component />
                  </AuthChecker>
                ) : (
                  <route.component />
                )
              }
            />
          ))}
        </Routes>
      </Layout>
      {(loading || loadingUser) && <Spinner />}
    </BrowserRouter>
  );
}

export default App;
