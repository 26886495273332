import { CSSProperties } from "react";

export const thStyle = {
  fontFamily: "Arial",
  fontSize: "13px",
  fontWeight: "normal",
  //border: "1px solid black",
};

export const thTextLeft: CSSProperties = {
  textAlign: "left",
};

export const thBorderRight = {
  borderRight: "1px solid black",
};

export const thStyleMinWidth = {
  minWidth: "300px",
  width: "300px",
};

export const spanStyleRed = {
  //color: "#cc0000",
  color: "red",
  fontFamily: "Arial",
  fontSize: "13px",
};

export const spanStyleRedBold = {
  //color: "#cc0000",
  color: "red",
  fontWeight: "bold",
};

export const thStyleRed = {
  backgroundColor: "#cc0000",
  color: "#ffffff",
  fontSize: "14px",
  //border: "1px solid black",
};

export const thStyleGray = {
  padding: "30px",
  backgroundColor: "#c0c0c0",
  fontSize: "14px",
  //border: "1px solid black",
};

export const thStyleBeige = {
  padding: "30px",
  backgroundColor: "#fabf8f",
  fontSize: "14px",
  //border: "1px solid black",
};

export const thStyleLightBeige = {
  padding: "30px",
  backgroundColor: "#fde9d9",
  fontSize: "14px",
  //border: "1px solid black",
};

export const thSubheaderLarge = {
  width: "180px",
  padding: "0px",
};

export const thSubheaderSmall = {
  width: "120px",
  padding: "0px",
};
