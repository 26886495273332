import { useContext, useEffect, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  Column,
  CellProps,
  usePagination,
  TableInstance,
} from "react-table";
import { TracklistContext } from "../contexts/TracklistContext";
import { UserContext } from "../contexts/UserContext";
import { Pagination } from "../components/Pagination";
import { ITrackList } from "../interfaces";
import { ExcelTable } from "./ExcelTable/ExcelTable";
import { Download } from "react-bootstrap-icons";

export interface IItem {
  id: string;
  date: any;
  name: string;
}

const TracklistTable = () => {
  const [data, setData] = useState<IItem[]>([]);
  const { getTracklistItems, tracklistItems, getTracklist, trackList } =
    useContext(TracklistContext);
  const [tableItems, setTableItems] = useState<ITrackList>();
  const [downloadTimeStamp, setDownloadTimeStamp] = useState<number>(0);

  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    if (currentUser) getTracklistItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (trackList) {
      setDownloadTimeStamp(Date.now());
      setTableItems({ ...trackList, eventDate: trackList.eventDate.toDate() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackList]);

  const handleDownload = (id: string) => {
    if (id) getTracklist(id);
  };

  useEffect(() => {
    let itemList: IItem[] = [];
    tracklistItems &&
      tracklistItems.length > 0 &&
      tracklistItems.map((item) => {
        return (itemList = [
          ...itemList,
          {
            id: item.id,
            date: item.eventDate.toDate().toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            name: item.eventName,
          },
        ]);
      });
    setData(itemList);
  }, [tracklistItems]);

  const columns = useMemo<Column<IItem>[]>(
    () => [
      {
        Header: "Event date",
        accessor: "date",
        width: "10%",
      },
      {
        Header: "Event name",
        accessor: "name",
        Cell: (props: CellProps<IItem>) => (
          <a href={`/gema-setlist/${props.row.original.id}`}>{props.value}</a>
        ),
      },
      {
        Header: "",
        accessor: "id",
        width: "20%",
        Cell: (props: CellProps<IItem>) => (
          <button
            className="btn btn-secondary"
            onClick={() => handleDownload(props.value)}
          >
            {" "}
            <Download size={15} /> Download as file
          </button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableProps: TableInstance<IItem> = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    page,
  } = tableProps;

  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  };

  return (
    <>
      {data && data.length > 0 && (
        <>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <input
              type="text"
              placeholder="Search..."
              className="form-control"
              onChange={handleFilterInputChange}
            />
          </div>
          <br />
          <table
            {...getTableProps()}
            className="table table-bordered tracklist-table"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        {
                          style: column.width
                            ? { width: column.width, maxWidth: column.width }
                            : {},
                        },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination {...tableProps} />
        </>
      )}

      <ExcelTable
        tableItems={tableItems}
        downloadTimeStamp={downloadTimeStamp}
      />
    </>
  );
};

export default TracklistTable;
