import { ReactElement, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { UserContext } from "../contexts/UserContext";
import { Plus } from "react-bootstrap-icons";
import CustomContainer from "../components/Container";
import TracklistTable from "../components/TracklistTable";

export default function Home(): ReactElement {
  const navigate = useNavigate();
  const { handleAuthChange, currentUser } = useContext(UserContext);

  useEffect(() => {
    handleAuthChange({
      err: () => {
        navigate("/login");
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomContainer>
      <Row className="overflow-auto" style={{ minHeight: "100px" }}>
        <Col xs={12} className="position-relative">
          <TracklistTable />
          {currentUser && !currentUser.isReadonlyUser &&
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/gema-setlist";
              }}
              color="danger"
              id="create-new-list-button"
            >
              <Plus size={22} /> Add new setlist
            </Button>
          }
        </Col>
      </Row>
    </CustomContainer>
  );
}
