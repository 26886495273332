import { ReactElement } from "react";
import { IArtist } from "../../interfaces";
import { AutoCompleteInput } from "../inputs/AutoCompleteInput/AutoCompleteInput";

interface IProps {
  item: IArtist;
  index: number;
  availableArtists: IArtist[];
  handleChange: (
    name: string,
    value: string,
    index: number,
    type: "tracks" | "artists"
  ) => void;
  required?: boolean;
  handleFocusOut?: any;
  readonly: boolean;
}

export default function ArtistItem({
  item,
  index,
  availableArtists,
  required,
  handleChange,
  handleFocusOut,
  readonly
}: IProps): ReactElement {
  const handleChangeValue = (name: string, value: string) => {
    handleChange(name, value, index, "artists");
  };

  return (
    <>
      <AutoCompleteInput
        data={availableArtists}
        //setNewVal={setNewVal}
        value={item.name}
        id={`name-${index}`}
        label={`${index + 1}. Artist / Band / Performer`}
        handleChange={handleChangeValue}
        required={required}
        handleFocusOut={handleFocusOut}
        readonly={readonly}
      />
    </>
  );
}
