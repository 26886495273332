import { TableInstance } from "react-table";
import { IItem } from "./TracklistTable";
  
export const Pagination = ({gotoPage, canPreviousPage, canNextPage, pageCount, previousPage, nextPage, pageOptions, setPageSize, state: {pageIndex, pageSize}}: TableInstance<IItem>) => {
    return <div className="pagination row">
    <div id="pagination-buttons" className="col-xs-12 col-sm-4">
      <input type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn" value={"<<"} />
      <input type="button" onClick={() => previousPage()} disabled={!canPreviousPage}  className="btn" value={"<"} />
      <input type="button" onClick={() => nextPage()} disabled={!canNextPage}  className="btn" value={">"} />
      <input type="button" onClick={() => gotoPage(pageCount-1)} disabled={!canNextPage}  className="btn" value={">>"} />
    </div>
    <div id="pagination-display" className="col-xs-6 col-sm-4">
      Page{" "}
      <strong>
        {pageIndex + 1} of {pageOptions.length}
      </strong>{" "}
    </div>
    <div id="pagination-select" className="col-xs-6 col-sm-4">
      <select
        value={pageSize}
        className="form-select"
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50, 100].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>
  </div>
}