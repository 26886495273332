import { useState } from "react";
import { Button } from "reactstrap";
import { Download } from "react-bootstrap-icons";
import { ITrackList } from "../interfaces";
import { ExcelTable } from "./ExcelTable/ExcelTable";

interface IFileProps {
  trackListValues?: ITrackList;
}

const DownloadComponent = ({ trackListValues }: IFileProps) => {
  
  const [tableItems, setTableItems] = useState<ITrackList>();
  const [downloadTimeStamp, setDownloadTimeStamp] = useState<number>(0);

  const handleDownload = () => {
    if(trackListValues) {
      setDownloadTimeStamp(Date.now())
      setTableItems(trackListValues)
    }
  }

  return (
    <>
      <Button
        color="primary"
        size="lg"
        type="button"
        style={{ marginRight: "10px" }}
        onClick={() => handleDownload()}
      >
        <Download size={22} /> Download as file
      </Button>
      <ExcelTable tableItems={tableItems} downloadTimeStamp={downloadTimeStamp} />
    </>
  );
};

export default DownloadComponent;
