import {
  query,
  where,
  collection,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
} from "@firebase/firestore";
import { auth, db } from "../firebase";
import { v4 as uuid } from "uuid";
import React, { ReactElement } from "react";

interface Props {
  children:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactNodeArray
    | React.ReactPortal;
}

interface todoItemInterface {
  value: string;
  itemId: string;
}

interface contextTypes {
  loading: boolean;
  todoItems: todoItemInterface[];
  addTodoItem(value: string): Promise<void>;
  updateTodoItem(params: { newValue: string; id: string }): Promise<void>;
  deleteTodoItem(id: string): Promise<void>;
  getTodoItems(): Promise<void>;
}

const contextDefaultVal: contextTypes = {
  loading: false,
  todoItems: [],
  addTodoItem: async () => {},
  updateTodoItem: async () => {},
  deleteTodoItem: async () => {},
  getTodoItems: async () => {},
};

export const TodosContext =
  React.createContext<contextTypes>(contextDefaultVal);

export default function TodosContextProvider({
  children,
}: Props): ReactElement {
  const [loading] = React.useState(false);
  const [todoItems, setTodoItems] = React.useState<todoItemInterface[]>([]);

  const addTodoItem = async (value: string) => {
    try {
      // document reference to be added
      const docRef = doc(
        db,
        "todo",
        uuid() /*unique id for new document, Note that firestore can do this for you if you leave the third parameter empty*/
      );
      const userId = auth.currentUser;
      if (userId !== null) {
        await setDoc(docRef, {
          userId: userId.uid,
          value,
        });

        alert(`Item ${value} added!`);
      }
    } catch (error) {
      alert(error);
    }
  };

  const getTodoItems = async () => {
    try {
      if (auth.currentUser !== null) {
        const userId = auth.currentUser.uid;

        // query to get only the documents that matches logged in user id
        const q = query(collection(db, "todo"), where("userId", "==", userId));
        const querySnapshot = await getDocs(q);

        // reset the todo items value
        setTodoItems([]);

        // map through the query result and assign the value to the todoItems state
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          setTodoItems((prev) => [
            ...prev,
            {
              itemId: doc.id,
              value: data.value,
            },
          ]);
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  const updateTodoItem = async (params: { newValue: string; id: string }) => {
    try {
      // reference to the document to update
      const docRef = doc(db, "todo", params.id);

      // Update the value of the todo item
      await updateDoc(docRef, {
        value: params.newValue,
      });

      alert(`Item updated!`);
    } catch (error) {
      alert(error);
    }
  };

  const deleteTodoItem = async (id: string) => {
    try {
      // reference to the document to delete
      const docRef = doc(db, "todo", id);
      await deleteDoc(docRef);

      alert(`item: ${id} deleted!`);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <TodosContext.Provider
      value={{
        loading,
        todoItems,
        addTodoItem,
        getTodoItems,
        updateTodoItem,
        deleteTodoItem,
      }}
    >
      {children}
    </TodosContext.Provider>
  );
}
