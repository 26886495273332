import { Audio } from "react-loader-spinner";

const Spinner = () => {
  return (
    <div className="spinner-overlay">
      <div className="spinner">
        <Audio color="#ff3b3f" width="80" height="80" visible={true} />
      </div>
    </div>
  );
};

export default Spinner;
