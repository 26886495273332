import React from "react";
import { Container } from "reactstrap";

interface Props {
  children: React.ReactNode;
  withoutBackgroundColor?: boolean;
  withoutPadding?: boolean;
  id?: string;
}

const CustomContainer = ({ children, id, withoutBackgroundColor, withoutPadding }: Props) => {
  return (
    <>
      <Container
        className={`mx-auto my-4 ${withoutPadding ? "" : "p-4"} ${
          !withoutBackgroundColor ? "bg-light" : ""
        }`}
        style={{ margin: "3rem 0" }}
        id={id ? id : undefined}
      >
        {children}
      </Container>
    </>
  );
};

export default CustomContainer;
