import { ReactElement, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TrackListForm from "../components/TrackListForm";
import { UserContext } from "../contexts/UserContext";

export default function TrackList(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const { handleAuthChange } = useContext(UserContext);

  useEffect(() => {
    handleAuthChange({
      err: () => {
        if (location.pathname !== "/" && location.pathname !== "/gema-setlist")
          navigate("/login");
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  useEffect(() => {
    if (location.pathname === "/") navigate("/gema-setlist");
  }, []);

  return (
    <div>
      <TrackListForm />
    </div>
  );
}
