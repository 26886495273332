import React, { ReactElement } from "react";
import {
  query,
  where,
  collection,
  getDoc,
  getDocs,
  doc,
  setDoc,
  updateDoc,
  orderBy,
} from "@firebase/firestore";
import { auth, db } from "../firebase";
import { v4 as uuid } from "uuid";
import { IArtist, ITrackList } from "../interfaces";

interface Props {
  children:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactNodeArray
    | React.ReactPortal;
}

interface contextTypes {
  loading: boolean;
  trackList: ITrackList | undefined;
  tracklistItems: ITrackList[];
  getTracklist(id: string): Promise<void>;
  saveTracklist(tracklist: ITrackList): Promise<void>;
  isTracklistSaved: boolean;
  getTracklistItems(): Promise<void>;
  resetTrackList(): Promise<void>;
}

const contextDefaultVal: contextTypes = {
  loading: false,
  isTracklistSaved: false,
  trackList: undefined,
  getTracklist: async () => {},
  tracklistItems: [],
  saveTracklist: async () => {},
  getTracklistItems: async () => {},
  resetTrackList:async () => {}
};

export const TracklistContext =
  React.createContext<contextTypes>(contextDefaultVal);

export default function TracklistContextProvider({
  children,
}: Props): ReactElement {
  const [trackList, setTracklist] = React.useState<ITrackList>();
  const [tracklistItems, setTracklistItems] = React.useState<ITrackList[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [isTracklistSaved, setIsTracklistSaved] = React.useState(false);

  const saveTracklist = async (list: ITrackList) => {
    try {
      setLoading(true);
      if (!list.id) list.id = uuid();
      const docRef = doc(db, "tracklists", list.id);
      const docSnap = await getDoc(docRef);

      let artists: string[] = [];

      const eventArtists = list.artists
        .map(({ name }) => {
          return name;
        })
        .filter((a) => a !== "");

      const authors1 = list.tracks
        .map(({ author1 }) => {
          return author1;
        })
        .filter((item) => item !== "");

      const authors2 = list.tracks
        .map(({ author2 }) => {
          return author2;
        })
        .filter((item) => item !== "");

      artists = eventArtists.concat(authors1).concat(authors2);

      await addArtists(artists);

      list.artists = list.artists.filter((a) => a.name !== "");
      list.tracks = list.tracks.filter((t) => t.name !== "");

      await Promise.all(
        list.artists.map(async (artist) => {
          const docRef = doc(db, "artists", artist.name);
          const docSnap = await getDoc(docRef);
          const data = docSnap.data();
          if (data) artist.id = data.id;
        })
      );

      list.tracks.map((t) => (t.tracklistId = list.id));

      if (!docSnap.exists()) {
        await setDoc(docRef, {
          list,
        });
      } else {
        await updateDoc(docRef, {
          list,
        });
      }

      setLoading(false);
      setIsTracklistSaved(true);
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  const getTracklistItems = async () => {
    try {
      if (auth.currentUser !== null) {
        setLoading(true);
        const colRef = collection(db, "tracklists");
        const querySnap = await getDocs(
          query(colRef, orderBy("list.eventDate", "desc"))
        );

        setTracklistItems([]);
        querySnap.forEach((doc) => {
          const data = doc.data().list;
          setTracklistItems((prev) => [
            ...prev,
            {
              id: doc.id,
              artists: data.artists,
              tracks: data.tracks,
              eventDate: data.eventDate,
              eventName: data.eventName,
              location: data.location,
              totalArtistCount: data.totalArtistCount,
              withWerknummer: data.withWerknummer,
              isGemaFree: data.isGemaFree
            },
          ]);
        });
        setLoading(false);
      }
    } catch (error) {
      alert(error);
      setLoading(true);
    }
  };

  const getTracklist = async (id: string) => {
    try {
      if (auth.currentUser !== null) {
        setLoading(true);
        setTracklist(undefined);

        const colRef = collection(db, "tracklists");
        const querySnap = await getDocs(
          query(colRef, where("list.id", "==", id))
        );

        if (querySnap.size) {
          setTracklist(querySnap.docs[0].data().list);
        }
        setLoading(false);
      }
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  const addArtists = async (trackListArtists: string[]) => {
    let result: IArtist[] = [];
    try {
      await Promise.all(
        trackListArtists.map(async (artist) => {
          artist = artist.replaceAll("/", "|");
          const docRef = doc(db, "artists", artist);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            result = [...result, { id: docSnap.data().id, name: artist }];
          } else {
            const newId = uuid();
            await setDoc(docRef, {
              id: newId,
              name: artist,
            }).then(() => {
              result = [...result, { id: newId, name: artist }];
            });
          }
        })
      );
      return result;
    } catch (error) {
      alert(error);
    }
  };

  const resetTrackList = async () => {
    setTracklist(undefined)
  }

  return (
    <TracklistContext.Provider
      value={{
        loading,
        trackList,
        getTracklist,
        saveTracklist,
        isTracklistSaved,
        getTracklistItems,
        tracklistItems,
        resetTrackList
      }}
    >
      {children}
    </TracklistContext.Provider>
  );
}
