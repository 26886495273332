import { ReactElement } from "react";
import { Plus } from "react-bootstrap-icons";
import { Button, Col, Row } from "reactstrap";
import { IArtist, ITrackList } from "../../interfaces";
import ArtistItem from "./ArtistItem";
import TextInput from "../inputs/TextInput";

interface IProps {
  trackListValues: ITrackList;
  artists: IArtist[];
  changeInput?: (e: any) => void;
  changeNestedItem: (
    name: string,
    value: string,
    index: number,
    type: "tracks" | "artists"
  ) => void;
  addArtist?: () => void;
  handleFocusOut: (name: string, value: string) => void;
  readonly: boolean;
}

function ArtistDataFields({
  trackListValues,
  artists,
  changeNestedItem,
  handleFocusOut,
  readonly,
}: IProps): ReactElement {
  var items: any[] = [];

  for (var i = 0; i < trackListValues.artists.length; i++) {
    items.push(
      <ArtistItem
        key={i}
        item={trackListValues.artists[i]}
        index={i}
        availableArtists={artists}
        handleChange={changeNestedItem}
        handleFocusOut={handleFocusOut}
        required={i === 0}
        readonly={readonly}
      />
    );
  }
  return <div>{items}</div>;
}

export default function ArtistDataFieldsComponent({
  trackListValues,
  artists,
  changeInput,
  changeNestedItem,
  addArtist,
  handleFocusOut,
  readonly,
}: IProps): ReactElement {
  return (
    <div className="my-4">
      <h5>Artists</h5>
      <ArtistDataFields
        artists={artists}
        trackListValues={trackListValues}
        changeNestedItem={changeNestedItem}
        handleFocusOut={handleFocusOut}
        readonly={readonly}
      />
      <Row>
        <Col xs="12" sm="6" md="4" className="p-0">
          <TextInput
            id="totalArtistCount"
            type="number"
            value={trackListValues.totalArtistCount?.toString() || ""}
            label="Total number of artists"
            handleChange={(e: any) => changeInput && changeInput(e)}
            required
            readonly={readonly}
          />
        </Col>
        {!readonly && (
          <Col xs="12" sm="6" md="8" style={{ paddingRight: 0 }}>
            <div style={{ marginTop: "0px" }} className="text-right">
              <Button
                color="secondary"
                onClick={() => addArtist && addArtist()}
              >
                <Plus size={22} />
                Add another artist
              </Button>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}
