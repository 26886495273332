import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useParams } from "react-router-dom";
import { ArtistContext } from "../contexts/ArtistContext";
import { Button, Col, Container, Form, Label, Row } from "reactstrap";
import TextInput from "./inputs/TextInput";
import DateInput from "./inputs/DateInput";
import { TracklistContext } from "../contexts/TracklistContext";
import { ITrackList, defaultTrack, defaultArtist } from "../interfaces";
import { Check2, ListUl } from "react-bootstrap-icons";
import { UserContext } from "../contexts/UserContext";
import ArtistDataFieldsComponent from "./fields/ArtistDataFieldsComponent";
import TrackDataFieldsComponent from "./fields/TrackDataFieldsComponent";
import CustomContainer from "./Container";
import TrackListFormButtons from "./TrackListFormButtons";

export default function TrackListForm(): ReactElement {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { getArtists, artists } = useContext(ArtistContext);
  const { saveTracklist, trackList, getTracklist, isTracklistSaved, loading } =
    useContext(TracklistContext);
  const { signInAnomyously, currentUser, deleteUser, loadingUser } =
    useContext(UserContext);

  const { id } = useParams();

  const defaultArtistNumber = 3;
  const defaultTrackNumber = 10;

  const [isFormReadonly, setIsFormReadonly] = useState<boolean>(false);
  const [formSubmitFailed, setFormSubmitFailed] = useState<boolean>(false);
  const [userStartedEditing, setUserStartedEditing] = useState<boolean>(false);
  const [trackListValues, setTrackListValues] = useState<ITrackList>({
    id: "",
    location: "XYZ Club Berlin",
    eventName: "",
    eventDate: new Date(),
    artists: [],
    tracks: [],
    totalArtistCount: undefined,
    withWerknummer: false,
    isGemaFree: false,
  });

  useEffect(() => {
    userStartedEditing && signInAnomyously();
  }, [userStartedEditing]);

  useEffect(() => {
    if (isTracklistSaved && currentUser && !currentUser.displayName) {
      const uid = currentUser.userId;
      deleteUser(uid);
    }
  }, [isTracklistSaved, currentUser]);

  useEffect(() => {
    if (currentUser) {
      getArtists();
      if (currentUser.isReadonlyUser) {
        setIsFormReadonly(true);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    id && getTracklist(id);
  }, [id, currentUser]);

  useEffect(() => {
    trackList &&
      setTrackListValues({
        id: trackList.id,
        location: "XYZ Club Berlin",
        eventName: trackList.eventName,
        eventDate: trackList.eventDate?.toDate(),
        artists: trackList.artists,
        totalArtistCount:
          trackList.totalArtistCount || trackList?.artists?.length || undefined,
        tracks: trackList.tracks,
        withWerknummer: trackList.withWerknummer ?? false,
        isGemaFree: trackList.isGemaFree ?? false,
      });
  }, [trackList]);

  useEffect(() => {
    const defaultArtists = [];
    const defaultTracks = [];

    if (trackListValues?.artists.length === 0) {
      for (var i = 0; i < defaultArtistNumber; i++) {
        defaultArtists.push(defaultArtist);
      }
    }

    if (trackListValues?.tracks.length === 0) {
      for (var j = 0; j < defaultTrackNumber; j++) {
        defaultTracks.push(defaultTrack);
      }
    }
    setTrackListValues({
      ...trackListValues,
      artists: defaultArtists,
      tracks: defaultTracks,
    });
  }, []);

  const handleFocusOut = (name: string, value: string) => {
    if (value) {
      const itemAlreadyExists = artists.findIndex(
        (item) => value.toLowerCase() === item.name.toLowerCase()
      );
      if (itemAlreadyExists < 0) artists.push({ id: "", name: value });
    }
  };

  const changeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrackListValues({
      ...trackListValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeGemaFree = (e: React.ChangeEvent<HTMLInputElement>) => {
    var isGemaFree = e.currentTarget.checked;
    var withWerknummer = trackListValues.withWerknummer;
    if (isGemaFree) {
      withWerknummer = false;
    }
    setTrackListValues({
      ...trackListValues,
      isGemaFree: isGemaFree,
      withWerknummer: withWerknummer,
    });
  };

  const handleChangeWithWerknummer = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTrackListValues({
      ...trackListValues,
      withWerknummer: e.currentTarget.checked,
    });
  };

  const changeDateValue = (fieldValue: Date, fieldName: string) => {
    setTrackListValues({ ...trackListValues, [fieldName]: fieldValue });
  };

  const changeNestedItem = (
    name: string,
    value: string,
    index: number,
    type: "tracks" | "artists"
  ) => {
    const propName = name.split("-")[0];

    let updatedItems = trackListValues[type].map((element, i) =>
      i === index ? { ...element, [propName]: value } : element
    );

    setTrackListValues({
      ...trackListValues,
      [type]: updatedItems,
    });
  };

  const addArtist = () => {
    setTrackListValues({
      ...trackListValues,
      artists: [...trackListValues.artists, defaultArtist],
    });
  };

  const addTrack = () => {
    setTrackListValues({
      ...trackListValues,
      tracks: [...trackListValues.tracks, defaultTrack],
    });
  };

  const handleReCaptchaVerify = useCallback(
    async (trackListValues: ITrackList) => {
      setFormSubmitFailed(false);
      if (!executeRecaptcha) {
        setFormSubmitFailed(true);
        console.log("Execute recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha("pandaGemaFormSubmit");
      if (token) {
        handleSubmitForm(trackListValues);
      } else {
        setFormSubmitFailed(true);
      }
    },
    [executeRecaptcha]
  );

  const handleSubmitForm = (trackListValues: ITrackList) => {
    saveTracklist(trackListValues);
  };

  const checkKeyDown = (e: any) => {
    if (e.code === "Enter" || e.key === "Enter") e.preventDefault();
    if (!userStartedEditing) setUserStartedEditing(true);
  };

  const AllSetlistsButton = () => {
    return currentUser && currentUser.displayName ? (
      <CustomContainer withoutBackgroundColor={true} withoutPadding={true}>
        <Row className="overflow-auto" style={{ minHeight: "60px" }}>
          <Col xs={12} className="position-relative">
            <Button
              color="danger"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/admin";
              }}
              style={{ position: "absolute", top: 0, right: "15px" }}
            >
              <ListUl size={22} /> All playlists
            </Button>
          </Col>
        </Row>
      </CustomContainer>
    ) : (
      <></>
    );
  };

  return loading || loadingUser ? (
    <></>
  ) : (
    <>
      <AllSetlistsButton />
      {isTracklistSaved && (
        <CustomContainer>
          <Check2 size={36} color={"green"} />{" "}
          <span style={{ color: "green" }}>
            Your setlist was successfully saved!
          </span>
        </CustomContainer>
      )}
      {(!isTracklistSaved || currentUser) && (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleReCaptchaVerify(trackListValues);
          }}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <input type={"hidden"} value={trackListValues.id} />
          <CustomContainer>
            <TextInput
              id="location"
              type="text"
              value={trackListValues.location}
              label="Location"
              readonly={true}
              handleChange={() => null}
            />
            <DateInput
              id="eventDate"
              value={trackListValues.eventDate}
              label="Event date"
              handleChange={(date: Date) => changeDateValue(date, "eventDate")}
              required
              readonly={isFormReadonly}
            />
            <TextInput
              id="eventName"
              type="text"
              value={trackListValues.eventName}
              label="Event Name"
              handleChange={(e: any) => changeInput(e)}
              required
              readonly={isFormReadonly}
            />

            <div
              className="custom-checkbox-wrapper"
              style={{ justifyContent: "flex-start" }}
            >
              <input
                type="checkbox"
                name="isGemaFree"
                id="isGemaFree-checkbox"
                checked={trackListValues.isGemaFree}
                onChange={(e) => handleChangeGemaFree(e)}
                disabled={isFormReadonly}
              />
              <Label for="isGemaFree-checkbox" className="check-box" /> GEMA
              free
            </div>
          </CustomContainer>
          <CustomContainer>
            <ArtistDataFieldsComponent
              artists={artists}
              trackListValues={trackListValues}
              changeNestedItem={changeNestedItem}
              changeInput={changeInput}
              addArtist={addArtist}
              handleFocusOut={handleFocusOut}
              readonly={isFormReadonly}
            />
          </CustomContainer>
          <CustomContainer>
            <TrackDataFieldsComponent
              artists={artists}
              trackListValues={trackListValues}
              changeNestedItem={changeNestedItem}
              addTrack={addTrack}
              handleFocusOut={handleFocusOut}
              withWerknummer={trackListValues.withWerknummer}
              isGemaFree={trackListValues.isGemaFree}
              setWithWerknummer={handleChangeWithWerknummer}
              readonly={isFormReadonly}
            />
          </CustomContainer>
          {formSubmitFailed && (
            <Container className="text-danger text-right">
              Form submission failed, please try again!
            </Container>
          )}
          <TrackListFormButtons
            trackListValues={trackListValues}
            readonly={isFormReadonly}
          />
        </Form>
      )}
    </>
  );
}
