import Home from "./pages/Home";
import Login from "./pages/Login";
import TrackList from "./pages/TrackList";

interface RouteType {
  path: string;
  component: any;
  name: string;
  protected: boolean;
  redirect?: any;
}

const routes: RouteType[] = [
  {
    path: "/",
    component: TrackList,
    name: "Setlist Form Screen",
    protected: false,
  },
  {
    path: "/gema-setlist/login",
    component: Login,
    name: "Login Screen",
    protected: false,
  },
  {
    path: "/gema-setlist/:id",
    component: TrackList,
    name: "Setlist Form Screen",
    protected: true,
  },
  {
    path: "/gema-setlist",
    component: TrackList,
    name: "Setlist Form Screen",
    protected: false,
  },
  {
    path: "/login",
    component: Login,
    name: "Login Screen",
    protected: false,
  },
  {
    path: "/admin",
    component: Home,
    name: "Admin View Screen",
    protected: true,
  },
];

export default routes;
