import React, { ReactElement, useState } from "react";
import DatePicker from "react-datepicker";
import Asterisk from "../Asterisk";

interface IProps {
  id: string;
  value: Date;
  handleChange: any;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  label?: string;
}

export default function DateInput({
  id,
  value,
  handleChange,
  readonly,
  label,
  required,
}: IProps): ReactElement {
  const [focus, setFocus] = useState<boolean>(false);
  return (
    <div
      className={`form-floating text-bold mb-3 ${
        focus || value ? "date-form-floating" : ""
      }`}
    >
      <DatePicker
        name={id}
        selected={value}
        onChange={(date: Date) => handleChange(date, id)}
        dateFormat={"dd.MM.yyyy"}
        className="form-control"
        autoComplete={"off"}
        onFocus={(e) => setFocus(true)}
        onBlur={(e) => setFocus(false)}
        required={required}
        readOnly={readonly}
        disabled={readonly}
      />
      <label htmlFor={id}>
        {label} {required && <Asterisk />}
      </label>
    </div>
  );
}
