import { ReactElement } from "react";
import { Plus } from "react-bootstrap-icons";
import { Button, Label } from "reactstrap";
import { IArtist, ITrackList } from "../../interfaces";
import TrackItem from "./TrackItem";

interface IProps {
  trackListValues: ITrackList;
  artists: IArtist[];
  changeNestedItem: (
    name: string,
    value: string,
    index: number,
    type: "tracks" | "artists"
  ) => void;
  addTrack?: () => void;
  withWerknummer?: boolean;
  isGemaFree?: boolean;
  setWithWerknummer?: (e: any) => void;
  handleFocusOut: (name: string, value: string) => void;
  readonly: boolean;
}

function TrackDataFields({
  trackListValues,
  artists,
  changeNestedItem,
  withWerknummer,
  handleFocusOut,
  readonly
}: IProps): ReactElement {
  var items: any[] = [];

  for (var i = 0; i < trackListValues.tracks.length; i++) {
    items.push(
      <TrackItem
        item={trackListValues.tracks[i]}
        index={i}
        withWerknummer={withWerknummer}
        availableArtists={artists}
        handleChange={changeNestedItem}
        key={i}
        readonly={readonly}
      />
    );
  }
  return <div>{items}</div>;
}

export default function TrackDataFieldsComponent({
  trackListValues,
  artists,
  changeNestedItem,
  addTrack,
  withWerknummer,
  isGemaFree,
  setWithWerknummer,
  handleFocusOut,
  readonly
}: IProps): ReactElement {
  return (
    <div className="my-4">
      <div className="d-flex justify-content-between">
        <h5>Tracks</h5>

        <div className="custom-checkbox-wrapper" title={isGemaFree ? "option not applicable if GEMA free is selected" : ""}>
          <input
            type="checkbox"
            id="werknummer-checkbox"
            checked={withWerknummer}
            onChange={(e) => setWithWerknummer && setWithWerknummer(e)}
    	      disabled={isGemaFree || readonly}
          />
          <Label for="werknummer-checkbox" className="check-box" /> 
          <span>with Werknummer</span>
        </div>
      </div>
      {isGemaFree &&
            <div  className="text-right" style={{fontSize: "13px"}}>option not applicable if "GEMA free" is selected</div>
          }         
      <TrackDataFields
        artists={artists}
        trackListValues={trackListValues}
        changeNestedItem={changeNestedItem}
        withWerknummer={withWerknummer}
        handleFocusOut={handleFocusOut}
        readonly={readonly}
      />
      {!readonly &&
        <div style={{ marginTop: "25px" }} className="text-right">
          <Button color="secondary" onClick={() => addTrack && addTrack()}>
            <Plus size={22} />
            Add another track
          </Button>
        </div>
      }
    </div>
  );
}
